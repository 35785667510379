var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.adInfor ? _c('div', [_c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_c('div', {
    staticClass: "login_title",
    on: {
      "click": _vm.backClick
    }
  }, [_vm._v(_vm._s(_vm.$t('myAd')))]), _vm._m(0), _c('div', {
    staticClass: "sub_title",
    staticStyle: {
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.$t('title.advertiseDetail')))])]), _vm.adInfor.check_status == 1 ? _c('div', {
    staticClass: "tip blue"
  }, [_vm._v(" " + _vm._s(_vm.$t('curState')) + "：" + _vm._s(_vm.$t('my.checkIn')) + " ")]) : _vm._e(), _vm.adInfor.check_status == 2 ? _c('div', {
    staticClass: "tip green"
  }, [_vm._v(" " + _vm._s(_vm.$t('curState')) + "：" + _vm._s(_vm.$t('my.applySuccess')) + " ")]) : _vm._e(), _vm.adInfor.check_status == 3 ? _c('div', {
    staticClass: "tip red"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('curState')) + "：" + _vm._s(_vm.$t('my.applyFailure')))]), _c('div', {
    staticClass: "reason"
  }, [_vm._v(_vm._s(_vm.adInfor.remark))])]) : _vm._e(), _c('div', {
    staticClass: "all_list"
  }, [_c('div', {
    staticClass: "list_item"
  }, [_c('div', {
    staticClass: "list_item_top"
  }, [_vm._v(" " + _vm._s(_vm.$t('order.adPosition')) + " ")]), _c('div', {
    staticClass: "list_item_bottom"
  }, [_vm._v(" " + _vm._s(_vm.adInfor.order_info.ads_type_name) + " ")])]), _c('div', {
    staticClass: "list_item"
  }, [_c('div', {
    staticClass: "list_item_top"
  }, [_vm._v(" " + _vm._s(_vm.$t('order.adContent')) + " ")]), _c('div', {
    staticClass: "list_item_bottom"
  }, [_c('div', {
    staticClass: "list_item_bottom_right"
  }, [_c('div', {
    staticClass: "ad_content"
  }, [_vm._v(" " + _vm._s(_vm.adInfor.information_name) + " ")])])])]), _c('div', {
    staticClass: "list_item"
  }, [_c('div', {
    staticClass: "list_item_top"
  }, [_vm._v(" " + _vm._s(_vm.$t('order.adDuration')) + " ")]), _c('div', {
    staticClass: "list_item_bottom"
  }, [_vm._v(" " + _vm._s(_vm.adInfor.order_info.num) + " ")])]), _c('div', {
    staticClass: "list_item",
    staticStyle: {
      "border-bottom": "none"
    }
  }, [_c('div', {
    staticClass: "list_item_top"
  }, [_vm._v(" " + _vm._s(_vm.$t('order.photo')) + "(" + _vm._s(_vm.$t('order.aSheet')) + ") ")]), _c('div', {
    staticClass: "list_item_bottom"
  }, [_c('div', {
    staticClass: "all_imgs"
  }, [_c('img', {
    attrs: {
      "src": _vm.adInfor.thumb_url
    }
  })])])])])])]) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "front_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/home_icons/front.png")
    }
  })]);
}];
export { render, staticRenderFns };