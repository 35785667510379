export default {
  data() {
    return {
      id: '',
      adInfor: ""
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getAdDetail();
  },
  methods: {
    getAdDetail() {
      this.$http.myAdsInfo({
        id: this.id
      }).then(res => {
        if (res.code == 1) {
          this.adInfor = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backClick() {
      this.$router.back();
    }
  }
};